@import "./../variables";

#Splash {
	background: url('../assets/img/background.jpg') no-repeat center top fixed;
	background-size:cover;
	width:100%;
	height:100vh;
	display:block;
}

#Content {
	text-align: center;
	position: relative;
	top:25vh;
	height: auto;
	width: 450px;
	margin: auto;
	background: rgba(#424242, 0.75);
	display:block;
	color:white;
  	border-radius: 25px;
	padding-bottom: 18px;
}

@media (max-width: 450px) {
	#Content {
		width: 100vw;
	}
	#Title {
		padding-left:2vw;
		padding-right:2vw;
		.name {
			padding-left:1vw;
			font-size: calc(60px + (90 - 60) * ((100vw - 300px) / (450 - 300)));
		}
	}
	#Sub-Title {
		div {
			font-size: calc(28px + (36 - 28) * ((100vw - 300px) / (450 - 300)));
		}
	}
}

#Title {
	//width:100%;
	line-height: 1.00;
	padding-left:16px;
	padding-right:16px;
}

#Sub-Title {
	text-shadow: 1px 1px 1px darken($brand-primary-contrast, 10);
	width:100%;
	float: center;
	margin: auto;
	text-align: center;
	font-weight: bold;
	font-size: 36px;
	//margin-bottom: 18px;
	display: flex;

	div {
		float:left;
		text-align:center;
		margin:0pt;
		flex-grow: 1;
		//width:80pt;
		padding:0px;
	}
}

.name {
	text-shadow: 2px 2px 1px rgba($dark-gray, 0.9);
	float:center;

	//line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));

	font-size: 90px;
	font-family: $signature-font;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 24px;
	font-style: italic;
	color:$brand-primary;
	.first {
		float:left;
	}
	.last {
		padding-left: 12px;
	}
}
