@import "./fonts";

$footer-background-colour: rgb(32,32,32);

$owen-blue: #03A9F4;
$owen-l-blue: #7dd6ff;
$owen-purple: #673AB7;
$owen-green: #64DD17;
$owen-orange: #F9A825;

$rich-purple: #522cac;
$primary-blue:#0095F0;
$dark-gray: #424242;
$contrast-gray: #666666;
$mid-gray: #999999;
$light-gray: #F0F3F5;

//$spacing: 50px;
//$sm-width: 767px;
//$padding: 20px;
//$border-radius: 10px;
$header-background: black;//#25292a;//#747474;
$brand-primary-contrast: $dark-gray;
$brand-primary: $owen-blue;
$brand-secondary: $owen-l-blue;
$brand-logo-height: 70px;
$message-box-lighten: 10;
$record: $brand-secondary;//#FFB74D;

$ai: $owen-green;
$data: $owen-orange;
$digital: lighten($owen-purple, 20);

$signature-font: 'Great Vibes', cursive;
$header-height: 50px;


.highlight {
	color:$brand-primary;
	font-weight: bold;
}


@keyframes blink-data {
	from, to { color: transparent; }
	50% { color: $data; }
}

.data {
	color: $data;
	text-shadow: 0px 0px 0px $data, 1px 1px 1px darken($data, 25);

	.blink {
		animation: blink-data .75s step-end infinite;
	}
}

@keyframes blink-digital {
	from, to { color: transparent; }
	50% { color: $digital; }
}

.digital {
	color: $digital;
	text-shadow: 0px 0px 0px $digital, 1px 1px 1px darken($digital, 25);

	.blink {
		animation: blink-digital .75s step-end infinite;
	}
}

@keyframes blink-ai {
	from, to { color: transparent; }
	50% { color: $ai; }
}

.ai {
	color: $ai;
	text-shadow: 0px 0px 0px $ai, 1px 1px 1px darken($ai, 25);
}
.ai.blink {animation: blink-ai .75s step-end infinite;}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
