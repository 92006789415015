@import "./../variables";

#Footer {
	width:100%;
	position: fixed;
	padding-top: 8px;
	padding-bottom: 8px;
    bottom: 0;
    left: 0;
	font-size: 18px;

	text-align: center;
	background-color: rgba($brand-primary-contrast, 0.9);
	color:white;

	a {
		color:white;
		font-style:normal;
		text-decoration:none;
	}
}

.icon {
	height:24px;
	width:auto;
	margin:4px;
}
.icon.email{
	margin-left:0px;
}
